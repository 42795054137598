/* ContactsPage section */
.contacts {
    padding: 2% 15%;
}

#contacts-hr {
  height: 0.5px;
  opacity: 1;
  color: #242124;
  width: 40%;
  margin: 0 auto;
}

.contacts-header {
     font-size: 4rem;
     font-weight: bold;
     text-align: left;
     color: #242124;
     margin-bottom: 1%;    
}

.contacts-items {
  padding: 3.5% 1% 1%;
}

.contacts-items-text {
    font-size: 1.7rem;  
    font-weight: thin;
    color: #242124;
    text-align: justify;
    text-justify: inter-word;
    display: inline-block;
    margin-bottom: 4%;
}

.contacts-items-text li {
  margin-bottom: 5%;
}

.contact-social-items {
    margin:1% auto ;
}

.contact-social-item {
    margin: auto 15px;
    border: 1px solid #242124;
    padding: 1.2% 1%;
    border-radius: 50%;
}

.contact-social-logo {
    font-size: 1.5rem;
}
.contact-social-logo:hover {
    color: #F9F8F8;
}
  
.facebook:hover {
    background-color: #3B5998;
    color: #F9F8F8;
  }
.linkedin:hover {
    background: #0A66C2;
    color: #F9F8F8;
  }
.youtube:hover {
    background: #bb0000;
    color: #F9F8F8;
  }
.instagram:hover{
    color: #F9F8F8; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

  .wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 3s;        /* Change to speed up or slow down */
    animation-iteration-count: 3;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }

.contacts-form {
  width: 100%;
  height: 570px;
  border: none;
}

  /* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .contacts {
    padding: 3% 10% 1%;
}

.contacts-header {
     font-size: 2.5rem;
     font-weight: bold;
     text-align: left;
     color: #242124;
     margin-bottom: 10%;    
}

.contacts-items {
  padding: 2% auto;
}


}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .contacts {
    padding: 3% 10% 1%;
}

.contacts-header {
     font-size: 2.5rem;
     font-weight: bold;
     text-align: left;
     color: #242124;
     margin-bottom: 10%;    
}

.contacts-items {
  padding: 2% 1%;
}
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .contacts {
    padding: 5% 5%;
}

.contacts-header {
    font-size: 2.5rem;
    margin-bottom: 4%;
}

.contacts-items {
    padding: 2% 1%;
}
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .contacts {
    padding: 5% 5%;
}

.contacts-header {
    font-size: 2.5rem;
    margin-bottom: 4%;
}

.contacts-items {
    padding: 2% 1%;
}
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
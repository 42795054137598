.video-bg {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .video-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .video-bg .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
  }

  /* TEXT OVER VIDEO SECTION */
  .hero-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    padding: 1% 15%;
  }

  .hero-title {
   font-size: 9rem;  
   line-height: 1.2;
   font-weight: 500;
   color: #F9F8F8;
   position: relative;
   display: inline-block;
   margin-right: 1rem;

}

.hero-title::after {
   content: "";
   width: 100%;
   height: 5px;
   background-color: #F9F8F8;
   border-radius: 3rem;
   position: absolute;
   background-position: center;
   background-size: contain;
   bottom:0 ;
   left:0;
}

/* Hero Quote Button */
.hero_quote_button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
  border: 1px solid #F9F8F8;
  color: #F9F8F8;
  font-weight: 400;
  padding: .75rem 1.25rem;
  font-size: 1.7rem;
  letter-spacing: .05rem;
  cursor: pointer;
  transition: all .3s;
  margin-top: 5%;  
  
}

.hero_quote_button_text {
  position: relative;
  z-index: 2;
}

.hero_quote_button_fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #FFEE01;
  transition: width 0.3s ease;
}

.hero_quote_button:hover {
  color: #242124;
  border: 1px solid #FFEE01;
  transform: none;
  transition: all .3s;
  
}

.hero_quote_button:hover .hero_quote_button_fill {
  width: 100%;

}

/* Hero Ratings Button */
.hero-rating-button {
  position: relative;
  display: inline-block;
  border:none;
  background-color: transparent;
  margin-top: 5%;
  margin-right: 5%;
  padding: 0;
  cursor: pointer;
  
}

.hero-rating-logo {
  width: 2rem;
  height: auto;
  margin-right: 0.5rem;
}

.hero-rating-star {
  font-size: 1.4rem;
  color: #FFCD3C;
  
}

.hero-rating-button p {
  font-size: 1.4rem;
  color: #F9F8F8;
  text-align: right;
}

/* PLAY BUTTON  */
.hero-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-play-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 200px;
  height: 100px;
  background-color: transparent;
  border: 1px solid #F9F8F8;
  color: #F9F8F8;
  font-weight: 400;
  padding: .75rem 1.25rem;
  font-size: 5rem;
  cursor: pointer;
  transition: all .3s;
}

.hero-play-button_text {
  position: relative;
  z-index: 2;
}

.hero-play-button_fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #1D8BCA;
  transition: width 0.3s ease;
}

.hero-play-button:hover {
  color: #F9F8F8;
  border: 1px solid #1D8BCA;
  transform: none;
  transition: all .3s;
  
}

.hero-play-button:hover .hero-play-button_fill {
  width: 100%;

}

.hero-play p {
  margin-top: 5px;
  text-align: center;
  font-size: 1.5rem;
  color: #F9F8F8;

}

/* Play WINDOW */
.play-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.play-window-items {
  position: relative;
  width: 73%;
  height: 85%;
  overflow: hidden;
  background-color: #F9F8F8;
  border-radius: 8px;
  padding: 0.5rem;
}

.play-window-close {
  position: absolute;
  top: 3.5%;
  right: 10%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-window-close-icon {
  font-size: 3rem;
  color: #F9F8F8;
}

.mobile-items {
  display: none;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .hero-container {
    text-align: center;
    padding: 1% 10%;
    top: 45%;
  }
  .hero-title-container {
    text-align: center;
  }
  .hero-title {
    font-size: 6rem;
    text-align: left;
  }
  .hero-quote {
    position: fixed;
    top: 100%;
    /* left: 50%; */
    right: 10%;
    /* transform: translateX(-50%); */
    margin-top: 5%;
  }
  .hero-rating {
    position: fixed;
    top: 100%;
    /* bottom: -160px; */
    left: 10%;
    margin-top: 5%;
  }
  .hero-play {
    margin-top: 10%;
  }
  .hero-play-button {
    width: 110px;
    height: 50px;
    font-size: 2rem;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .hero-container {
    text-align: center;
    padding: 1% 10%;
    top: 45%;
  }
  .hero-title {
    font-size: 6rem;
  }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .hero-container {
    padding: 1% 10%;
    
  }
  .hero-title {
    font-size: 7rem;
  }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .hero-title {
    font-size: 8rem;
  }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
.services-card-even,
.services-card-odd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 5% 5%;
}

.services-card-even {
    background-color: #F9F8F8;
}

.services-card-odd {
    background-color: #fff;
}

.services-card-even:hover, .services-card-odd:hover {
    background-color: rgb(0, 0, 0, 0.05);
}

.service-parent-page-img {
    height: auto;
    width: 350px;
    padding: 2% 2%;
    transition: 1s;   
}

.service-home-page-img {
    height: auto;
    width: 150px;
    padding: 2% 2%;
    transition: 1s;   
}

.service-parent-page-img:hover, .service-home-page-img:hover {
    -ms-transform: scale(1.15); /* IE 9 */
  -webkit-transform: scale(1.15); /* Safari 3-8 */
  transform: scale(1.15); 
}

.services-card-img-text {
    font-size: 1.5rem;  
    line-height: 1.5;
    font-weight: 400;
    color: #242124;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .service-home-page-img, .service-parent-page-img {
    width: 200px;
}
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .service-parent-page-img {
    width: 150px;
  }
}
/* footer section */
.footer {
    padding: 1% 15%;
}

.footer-flag {
    padding: 2% 0;
}

.footer-hr {
    border-top: 1px solid #242124;
    margin: 10px auto;
    width: 80%;
}

.footer-flag p {
    font-size: 2rem;
    font-weight: bold;
    color: #242124;
    margin: 3% 0;
}

.footer-link-items {
    margin: 2.5% 0;
}

.footer-link-text {
    font-size: 1.5rem;
    line-height: 2.5;  
    font-weight: thin;
    color: #242124;
    text-align: left;
    display: inline-block;  
}
.footer-link-text a:hover {
    color: #1D8BCA;
}

.footer-counter-title {
    font-size: 1.5rem;
    font-weight: 700;
    /* margin: 5%;  */
	color: grey;
}

.footer-section-heading {
    font-size: 1.5rem;  
    line-height: 1.5;
    font-weight: bold;
    color: #242124;
    position: relative;
    display: inline-block;
}
.footer-section-heading::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #1D8BCA;
    border-radius: 3rem;
    position: absolute;
    background-position: center;
    background-size: contain;
    bottom:-4px ;
    left:0;
 }



.footer-span{
    font-size: 2rem;  
    font-weight: thin;
    color: #1D8BCA;
}

.foo-logo {
    margin: 0 15px;
    color: #242124;
    border: 1px solid #242124;
    padding: 5px;
    font-size: 1.5rem;
    width: 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
}

.foo-logo:hover {
    color: #F9F8F8;
}

.footer-column {
    padding: 3% 1%;
}

.footer-copyright {
    color: #242124;
    margin-top: 3%;
    font-size: 1.5rem;
    font-weight: 500;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
    .footer {
        padding: 1% 7% 10rem;
    }
    .footer-link-text {
        text-align: center;
        display: inline-block;
        margin-top: 5%;
    }

    .footer-counter {
       text-align: left;
       display: inline-block;
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .footer-link-text {
        text-align: center;
        display: inline-block;
        margin-top: 5%;
    }

    .footer-counter {
       text-align: left;
       display: inline-block;
    }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
.footer {
 padding: 1% 7%;
}
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
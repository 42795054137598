.timeline {
    position: relative;
    padding: 1% 0;
    margin: 3% 15%;
  }
  
  .timeline-item {
    position: relative;
    width: 95%;
    margin: 0 auto;
    padding: 40px 0;
  }

  .timeline-item-image {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    text-align: center;
    padding: 2%;
  }

  .timeline-item-image img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  
  .left-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: right;
    color: #1D8BCA;
  }
  
  .right-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
    color: #1D8BCA;
  }
  
  .timeline-item-text {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    text-align: center;
    padding: 2%;
  }

  .timeline-text {
    font-size: 2rem;
    text-align: justify;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .timeline-dot {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    height: 100%;
    background-color: #1D8BCA;
  }
  
  /* DOT TOP MIDDLE BOTTOM */
  .dot-top {
    position: absolute;
    color: #1D8BCA;
    font-size: 1.6rem;
    left: -6.5px;
    top:-2px ;
  }

  .dot-middle {
    position: absolute;
    top: 50%;
    color: #1D8BCA;
    font-size: 2rem;
    left: -8px;
  }

  .dot-btm {
    position: absolute;
    color: #1D8BCA;
    font-size: 1.6rem;
    left: -6.5px;
    bottom: -2px;
  }

  /* section heading */
  .section-heading {
    font-size: 3rem;  
    line-height: 1.5;
    font-weight: bold;
    color: #242124;
    position: relative;
    display: inline-block;
}
.section-heading::after {
    content: "";
    width: 100%;
    height: 4px;
    background-color: #1D8BCA;
    border-radius: 3rem;
    position: absolute;
    background-position: center;
    background-size: contain;
    bottom:-4px ;
    left:0;
 }

/* Extra small devices (phones) */
@media only screen and (min-width: 10px) and (max-width: 575.98px) {
  .timeline {
    margin: 3% 10%;
  }  
  .timeline-item {
        width: 100%;
        padding: 20px 0;
    }
    .left-title, .right-title {
        text-align: left;
    }
    .timeline-text {
        font-size: 1.7rem;
       }
       .timeline-dot {
        left: -20px;
      }
      .dot-top, .dot-btm {
        font-size: 1.4rem;
        left: -6px;
        
      }
      .dot-middle {
        font-size: 1.6rem;
        left: -6.5px;
        top: 25%;
      }
      .section-heading {
        font-size: 2.5rem;  
        font-weight: 500;
    }
    .section-heading::after { 
        height: 3px; 
     }
    
  }
  
  /* Small devices (tablets) */
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .timeline {
      margin: 3% 10%;
    }   
    .timeline-item {
          width: 100%;
          padding: 20px 0;
      }
      .left-title, .right-title {
          text-align: left;
      }
      .timeline-text {
          font-size: 1.7rem;
         }
         .timeline-dot {
          left: -20px;
        }
        .dot-top, .dot-btm {
          font-size: 1.4rem;
          left: -6px;
          
        }
        .dot-middle {
          font-size: 1.6rem;
          left: -6.5px;
          top: 25%;
        }
  }
  
  /* Medium devices (laptops) */
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .timeline {
      margin: 3% 5%;
    }    
    .timeline-item {
        width: 100%;
        padding: 20px 0;
    }
    .timeline-text {
        font-size: 1.6rem;
       }
      
      .dot-top, .dot-btm {
        font-size: 1.2rem;
        left: -4.5px;
      }
      .dot-middle {
        font-size: 1.4rem;
        left: -5.5px;
      }
  }
  
  /* Large devices (desktops) */
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .timeline {
      margin: 3% 5%;
    }    
    .timeline-item {
        width: 100%;
        padding: 20px 0;
    }
    .timeline-text {
        font-size: 1.7rem;
       }
      .dot-top, .dot-btm {
        font-size: 1.4rem;
        left: -5.5px;
      }
      .dot-middle {
        font-size: 1.6rem;
        left: -6.5px;
      }
  }
  
  /* Extra large devices (large desktops) */
  @media only screen and (min-width: 1640px) and (max-width: 2560px) {
    .timeline-item {
        width: 100%;
    }
    .left-title, .right-title {
        font-size: 3rem;
    }
    .timeline-text {
        font-size: 2.5rem;
       }
  }
  

  
.our-videos {
    padding: 1% 5%;
    margin: 5% auto;
}

.our-videos-slider {
  margin-top: 2%;
}

/* .slider-arrows {
  margin: 2% auto;
}

.slider-prevarrow, .slider-nextarrow {
margin: 1%;
font-size: 3rem;
color: #1D8BCA;
cursor: pointer;
transition: 0.3s ease;

}

.slider-prevarrow:hover, .slider-nextarrow:hover {
scale: 1.1;
transition: 0.3s ease;
} */

/* Arrows Left */

.arrow-container {
  display: flex;
  justify-content: space-between;
  width: 120px;
  height: 60px;
  margin: 0 auto;
}

.arrow {
  cursor: pointer;
  height: 100%;
  position: relative;
  transition: transform 0.1s;
  width: 100%;
  max-width: 4rem;
  margin: auto 0;
}

.arrow-top,
.arrow-bottom {
  background-color: #A7A7A7;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.arrow-top::after,
.arrow-bottom::after {
  background-color: #1D8BCA;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}

.arrow-left .arrow-top {
  transform: rotate(40deg);
  transform-origin: bottom left;
}

.arrow-left .arrow-bottom {
  transform: rotate(-40deg);
  transform-origin: bottom left;
}

.arrow-left .arrow-top::after {
  left: 100%;
  right: 0;
  transition-delay: 0;
}

.arrow-left .arrow-bottom::after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}

.arrow-left:hover .arrow-top::after {
  left: 0;
  transition-delay: 0s;
}

.arrow-left:hover .arrow-bottom::after {
  right: 0;
  transition-delay: 0.15s;
}

.arrow:active {
  transform: scale(0.9);
}

.arrow-left {
  left: 0;
}

/* Arrow Right */

.arrow-top2,
.arrow-bottom2 {
  background-color: #A7A7A7;
  height: 2px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.arrow-top2:after,
.arrow-bottom2:after {
  background-color: #1D8BCA;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}

.arrow-top2 {
  transform: rotate(40deg);
  transform-origin: bottom right;
}

.arrow-top2:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}

.arrow-bottom2 {
  transform: rotate(-40deg);
  transform-origin: top right;
}

.arrow-bottom2:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}

.arrow:hover .arrow-top2:after {
  left: 0;
  transition-delay: 0.15s;
}

.arrow:hover .arrow-bottom2:after {
  right: 0;
  transition-delay: 0s;
}

.arrow-right {
  right: 0;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .our-videos {
    padding: 1% 2%;
}
  .arrow-container {
    width: 70px;
    height: 50px
  }
  .arrow {
    max-width: 2rem;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .our-videos {
    padding: 1% 2%;
}
  .arrow-container {
    width: 70px;
    height: 50px
  }
  .arrow {
    max-width: 2rem;
  }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .our-videos {
    padding: 1% 2%;
}
  .arrow-container {
    width: 70px;
    height: 50px
  }
  .arrow {
    max-width: 2rem;
  }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .our-videos {
    padding: 1% 2%;
}
  .arrow-container {
    width: 70px;
    height: 50px
  }
  .arrow {
    max-width: 2rem;
  }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}

.dnavbar {
  position: sticky;
  display: flex;
  top: 0;
  left: 0;
  width: auto;
  height: 80px; /* Set a fixed height for the navbar */
  min-height: 80px; /* Set a minimum height for the navbar */
  align-items: center;
  padding: 1% 15%;
  background-color: rgba(0, 0, 0, 0.1);
  color: #242124;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
}
.dnavbar.scrolled {
  background-color: #F9F8F8;
  color: #242124;
}

.dnavbar_logo_text {
  font-size: 2.5rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  
}
.dnavbar_logo_text:hover {
  color: #242124;
}
.dnavbar.scrolled .dnavbar_logo_text:hover {
  color: #242124;
}

.dnavbar_logo_img {
  width: 5rem;
  height: 5rem;
}

.dnavbar__links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto 0;
}

.dnavbar__link {
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 3rem;
  transition: 0.3s ease;
}

.dnavbar__link:hover {
    transition: 0.3s ease;
    color: #1D8BCA;
}

/* dnavbar button */
.dnavbar_button {
  position: relative;
  overflow: hidden;
  background-color: #1D8BCA;
  border: 1px solid #1D8BCA;
  color: #F9F8F8;
  font-weight: 400;
  padding: .75rem 1.25rem;
  font-size: 1.5rem;
  letter-spacing: .05rem;
  cursor: pointer;
  transition: all .3s;
  margin-left: 3rem;
}

.dnavbar_button_text {
  position: relative;
  z-index: 2;
}

.dnavbar_button_fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #FFEE01;
  transition: width 0.3s ease;
}

.dnavbar_button:hover {
  color: #242124;
  border: 1px solid #FFEE01;
  transform: scale(1.1);
  transition: all .3s;
  
}

.dnavbar_button:hover .dnavbar_button_fill {
  width: 100%;
  
}
/* dnavbar button end */

/* After scroll button style */
.dnavbar.scrolled .dnavbar_button {
  background-color: #1D8BCA;
  border: 1px solid #1D8BCA;
 
}

.dnavbar.scrolled .dnavbar_button:hover {
  border: 1px solid #FFEE01;
}

/* Rotate Icon */
.dnavbar__link.rotate-icon .caret-icon {
  transition: transform 0.5s ease-in-out;
  transform: rotate(-180deg); /* Anticlockwise */
}

.dnavbar__link:not(.rotate-icon) .caret-icon {
  transition: transform 0.5s ease-in-out;
  transform: rotate(0); /* clockwise */
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .dnavbar {
    padding: 1% 10% 1% 8.5%;
  }
  .dnavbar__link {
    display: none;
  }
  .dnavbar_logo_text {
    font-size: 2rem;
  }
  .dnavbar_logo_img {
    width: 4rem;
    height: 4rem;
  }
  .dnavbar_button {
    display: none;
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .dnavbar {
    padding: 1% 5%;
  }

  .dnavbar__link {
    display: none;
  }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .dnavbar {
    padding: 1% 5% 1% 4%;
  }
  .dnavbar__link {
    display: none;
  }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .dnavbar {
    padding: 1% 5% 1% 4%;
  }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
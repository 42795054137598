/* Image Modal Window */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .image-modal-items {
    position: relative;
    width: fit-content;
    height: 85%;
    overflow: hidden;
    background-color: #F9F8F8;
    border-radius: 8px;
    padding: 0.5rem;
  }
  
  .image-modal-close {
    position: absolute;
    top: 3.5%;
    right: 10%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-modal-close-icon {
    font-size: 3rem;
    color: #F9F8F8;
  }
.toggle-menu {
  position: relative;
}

.toggle-menu-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3rem;
}

.toggle-menu-icon {
  font-size: 2.5rem;
}

.toggle-menu-items {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #242124;
  list-style: none;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 9999;
}

.toggle-menu.open .toggle-menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggle-menu-item {
  cursor: pointer;
  margin-top: 2.5rem;
}

.toggle-menu-item-text {
  font-size: 2.5rem;
  color: #F9F8F8;
  font-weight: 300;
}

.toggle-menu-item-text:hover {
  color: #1d8bca;
}

/* Toggle menu logo icon */
.toggle-menu-logo {
  position: absolute;
  top: 2.2%;
  left: 15%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-menu-logo-text {
  font-size: 2.5rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.toggle-menu-logo-text:hover {
  color: #F9F8F8;
}
.navbar.scrolled .toggle-menu-logo-text {
  color: #F9F8F8;
}

.toggle-menu-logo-img {
  width: 5rem;
  height: 5rem;
}

/* toggle menu close icon */
.toggle-menu-close {
  position: absolute;
  top: 3.5%;
  right: 15%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-menu-close-icon {
  font-size: 3rem;
  color: #F9F8F8;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {

}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
 
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
/* Container for the entire page */
.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    background-color: #1D8BCA;
    text-align: center;
    padding: 20px;
  }
  
  /* Checkmark Icon Styling */
  .checkmark {
    font-size: 100px;
    color: lightgreen;
    margin-bottom: 20px;
  }
  
  /* Heading styling */
  .thank-you-heading {
    font-size: 4rem;
    color: #f9f8f8;
    margin-bottom: 10px;
  }
  
  /* Message styling */
  .thank-you-message {
    font-size: 2rem;
    color: #f9f8f8;
    max-width: 600px;
  }
  
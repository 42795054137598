/* DESCRIPTION MAIN */
.service-desc {
    margin: 3% auto;
    padding: 0 15%;
}

#hr-service {
    width: 50%;
    color: #242124;
    height: 0.5px;
    margin: 4% auto;
    opacity: 0.5;
}

#hr-service2 {
    width: 35%;
    color: #242124;
    height: 0.5px;
    margin: 3% auto;
    opacity: 0.5;
}

.desc-text1 {
    font-size: 2rem;
    color:#242124;
    padding: 2% 0;
    flex: 1;
    line-height: 1.6;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
}

.desc-text2 {
    font-size: 5rem;
    color:#393764;
    white-space: pre-line;
    text-align: left;
    font-weight: 600;
    
}

.desc-text3 {
    font-size: 2rem;
    color:#242124;
    flex: 1;
    line-height: 1.6;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-word;
    margin: 2.5% auto;
}

.desc-text4 {
    font-size: 2rem;
    color: #00324B;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    list-style:disc;
    padding: 0 auto;
    margin: 0;
    height: 100%;
    text-align: justify;   
}
.desc-text4 li {
    margin: 1rem 0;
}
.desc-text4 span {
    font-weight: 400;
}

.desc-text5 {
    font-size: 2rem;
    color:#242124;
    flex: 1;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    margin-top: 5%;
    white-space: pre-line;
}

.desc-text6 p{
    font-size: 2rem;
    color:#1D8BCA;
    margin-top: 0;
    flex: 1;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
}


.desc-text1 span, .desc-text5 span {
    color: #1D8BCA;
    flex: 1;
}


.desc-img {
    width: 100%;
    max-width: 100%;
    height: auto;

}

/* FLAG 1 */
.flag {
    margin: 3% 0;
    padding: 2% 15%;
    background-color: #1d8bca;
    color: #F9F8F8;
}

.flag-text1 {
    font-size: 2rem;
    color: #FFB574;
    font-weight: 600;
}

.flag-text2 {
    font-size: 3rem;
    color: #F9F8F8;
    font-weight: 600;
}

.flag-text3 {
    font-size: 2rem;
    color: #F9F8F8;
    font-weight: 400;
}

/* FLAG Quote Button */
.flag_quote_button {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background-color: #F9F8F8;
    border: 1px solid #F9F8F8;
    color: #1D8BCA;
    font-weight: 400;
    padding: .75rem 1.25rem;
    font-size: 1.7rem;
    letter-spacing: .05rem;
    cursor: pointer;
    transition: all .3s;
    margin: 1%; 
    
  }
  
  .flag_quote_button_text {
    position: relative;
    z-index: 2;
  }
  
  .flag_quote_button_fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #FFEE01;
    transition: width 0.3s ease;
  }
  
  .flag_quote_button:hover {
    color: #242124;
    border: 1px solid #FFEE01;
    transform: none;
    transition: all .3s;
  }
  
  .flag_quote_button:hover .flag_quote_button_fill {
    width: 100%;
  }

  /* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
    .service-desc {
        padding: 0 10%;
    }
    #hr-service2 {
        width: 35%;
        color: #242124;
        height: 0.5px;
        margin: 5% auto;
        opacity: 0.5;
    }
    .desc-text1 {
        font-size: 1.5rem;
        text-align: justify;
        text-justify: inter-word;
    }
    .desc-text2 {
        font-size: 3rem;
    }
    .desc-text3 {
        font-size: 1.7rem;
    }
    .desc-text4 {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2;
    }
    .desc-text5 p {
        font-size: 1.5rem;
        text-align: justify;
        text-justify: inter-word;
       
    }
    .desc-text6 p{
        font-size: 1.5rem;
        padding-top: 0;
        line-height: 1.5;
        text-align: justify;
        text-justify: inter-word;
        
    }
    .desc-img {
        margin: 5% 0;
    }
    .flag {
        padding: 2% 10%;
    }
    .flag-text1 {
        font-size: 1.7rem;
    }
    
    .flag-text2 {
        font-size: 2rem;
    }
    
    .flag-text3 {
        font-size: 1.7rem;
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .service-desc {
        padding: 0 10%;
    }
    .desc-text1 {
        font-size: 1.7rem;
        text-align: justify;
        text-justify: inter-word;
    }
    .desc-text2 {
        font-size: 3rem;
    }
    .desc-text3 {
        font-size: 1.7rem;
    }
    .desc-text4 {
        font-size: 1.8rem;
    }
    .desc-text5 p {
        font-size: 1.7rem;
        text-align: justify;
        text-justify: inter-word;
    }
    .desc-text5 span {
        font-size: 1.7rem;
    }
    .desc-img {
        margin: 2.5% 0;
    }
    .flag {
        padding: 2% 10%;
    }
    .flag-text1 {
        font-size: 1.7rem;
    }
    
    .flag-text2 {
        font-size: 2.5rem;
    }
    
    .flag-text3 {
        font-size: 1.7rem;
    }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .service-desc {
        padding: 0 5%;
    }
    .desc-text1 {
        font-size: 1.7rem;
       
    }
    .desc-text2 {
        font-size: 3rem;
    }
    .desc-text3 {
        font-size: 1.7rem;
    }
    .desc-text4 {
        text-align: left;
        font-size: 1.8rem;
    }
    .desc-text5 p {
        font-size: 1.7rem;
    }
    .desc-text5 span {
        font-size: 1.7rem;
    }
    .desc-img {
        margin: 2.5% 0;
    }
    .flag {
        padding: 2% 10%;
    }
    .flag-text1 {
        font-size: 1.7rem;
    }
    
    .flag-text2 {
        font-size: 2.5rem;
    }
    
    .flag-text3 {
        font-size: 1.7rem;
    }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .service-desc {
        padding: 0 5%;
    }
    .desc-text1 {
        font-size: 1.7rem;
       
    }
    .desc-text2 {
        font-size: 3rem;
    }
    .desc-text3 {
        font-size: 1.7rem;
    }
    .desc-text4 {
        text-align: left;
        font-size: 1.8rem;
    }
    .desc-text5 p {
        font-size: 1.7rem;
    }
    .desc-text5 span {
        font-size: 1.7rem;
    }
    .desc-img {
        margin: 2.5% 0;
    }
    .flag {
        padding: 2% 10%;
    }
    .flag-text1 {
        font-size: 1.7rem;
    }
    
    .flag-text2 {
        font-size: 2.5rem;
    }
    
    .flag-text3 {
        font-size: 1.7rem;
    }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}


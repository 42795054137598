.partners {
    padding: 1% 5% 5%;
}

.partner-logo {
    padding: 1.5rem 2.5rem;
    
}

.partner-logo-text {
   font-size: 4rem;
   font-weight: 600;
   color: #1D8BCA;
   margin: 2% auto;
   width: 50%;
   border: 1px solid #1D8BCA;
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
    .partner-logo {
        padding: 1rem 1rem;
    }

    .partner-logo-text {
       font-size: 2rem;
       margin: 4% auto;
       width: 90%;
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .partner-logo-text {
        font-size: 2.5rem;
        margin: 4% auto;
        width: 90%;
     }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .partner-logo-text {
        font-size: 3rem;
        margin: 4% auto;
        width: 90%;
     }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
/* Video Modal Window */
.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 0, 0, 0.90);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .video-modal-items {
    position: relative;
    width: 72.6%;
    height: 84.6%;
    overflow: hidden;
    background-color: transparent;
    border-radius: 8px;
    padding: 0.5rem;
  }
  
  .video-modal-close {
    position: absolute;
    top: 3.5%;
    right: 10%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video-modal-close-icon {
    font-size: 3rem;
    color: #F9F8F8;
  }

  /* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .video-modal-items {
    height: 50%;
    width: 100%;
    padding: 0;
  }
}
/* cta section */
.action {
    padding: 2% 25%;
    background-color: #1d8bca;
}

.action-items {
    margin: 0 auto;
}

.action-logo {
    margin: auto;
    max-width: 200px;
    max-height: 200px ;
    
}

.action-text {
    font-size: 1.7rem;
    color: #F9F8F8;  
    line-height: 1.5;
    font-weight: 400;
    text-align: left;
    transition: 0.5s ease;
}

.action-text a:hover {
    color: yellow;
    border-bottom: 1px solid #F9F8F8;
    transition: 0.5s ease;
}

.action-social-items {
    margin: 7.5% auto 0;
    text-align: left;
}

.action-social-item {
    margin-right: 7%;
    border: 1px solid #F9F8F8;
    padding: 5% 3%;
    border-radius: 50%;
    color: #F9F8F8;
    cursor: pointer;
}

.action-social-logo {
    font-size: 3rem;
}
.action-social-logo:hover {
    color: #F9F8F8;
}
  
.facebook:hover {
    background-color: #3B5998;
    color: #F9F8F8;
  }
.linkedin:hover {
    background: #0A66C2;
    color: #F9F8F8;
  }
.youtube:hover {
    background: #bb0000;
    color: #F9F8F8;
  }
.instagram:hover{
    color: #F9F8F8; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
    .action {
        padding: 2% 5%;
    }
    
    .action-items {
        margin: 3% auto 5%;
    }
    
    .action-logo {
        margin: 5% auto 3%;
        max-width: 100px;
        max-height: 100px;
    }
    
    .action-text {
        font-size: 1.7rem;
        text-align: center;
    }
    
    .action-social-items {
        text-align: center;
    }
    
    .action-social-item {
        margin: 0 5%;
        padding: 4% 3%;
    }
    
    .action-social-logo {
        font-size: 2rem;
    }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .action {
        padding: 2% 5%;
    }
    
    .action-items {
        margin: 3% auto 5%;
    }
    
    .action-logo {
        margin: 5% auto 3%;
        max-width: 100px;
        max-height: 100px;
    }
    
    .action-text {
        font-size: 1.7rem;
        text-align: center;
    }
    
    .action-social-items {
        text-align: center;
    }
    
    .action-social-item {
        margin: 0 5%;
        padding: 4% 3%;
    }
    
    .action-social-logo {
        font-size: 2rem;
    }
}

/* Medium devices (laptops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .action {
        padding: 2% 5%;
    }
    
    .action-items {
        margin: 3% auto;
    }
    
    .action-logo {
        margin: 3% auto;
        max-width: 200px;
        max-height: 200px;
    }
    
    .action-text {
        font-size: 1.7rem;
    }

    .action-social-item {
        padding: 4% 3%;
    }
    
    .action-social-logo {
        font-size: 2rem;
    }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .action {
        padding: 2% 20%;
    }
    
    .action-items {
        margin: 3% auto;
    }
    
    .action-logo {
        margin: 5% auto;
        max-width: 200px;
        max-height: 200px;
    }
    
    .action-social-item {
        padding: 4% 3%;
    }
    
    .action-social-logo {
        font-size: 2rem;
    }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
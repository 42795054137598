.services-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 3% 0;
    background-color: #F9F8F8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-height: 0;
    transition:  max-height 0.5s ease-in-out;  
  }

  .services-menu.open {
    max-height: fit-content;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  #service-menu-hr {
    height: 1px;
    color: #242124;
  }
  
  .services-menu__list {
    margin: 0 15%;
  }
  
  .services-menu__item {
    padding: 1.5%;
  }

  .services-menu__item:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .services-menu__title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #242124;
    text-align: left;
    margin-bottom: 10px;
  }
  
  .services-menu__text {
    color: rgba(0, 0, 0, 1);
    font-size: 1.35rem;
    text-align: justify;
    font-weight: 300;
    line-height: 1.5;
  }

  /* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .services-menu__list {
    margin: 0 5%;
  }
  .services-menu__item {
    padding: 15px;
  }
  .services-menu__title {
    font-size: 1.5rem;
  }
  .services-menu__text {
    font-size: 1.30rem;
    line-height: 1.4;
  }

}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {
  /* Styles for extra large devices */
}
  